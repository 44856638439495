import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/seo';
import settingsData from '../settings/settings.json';

const { siteTitle, seoDescription, fourOFourText } = settingsData;

const StyledFourOFour = styled.div`
	font-size: 2rem;

	& a {
		text-decoration: underline;
	}

	& a:hover {
		text-decoration: none;
	}
`;

const FourOFour = () => (
	<StyledFourOFour>
		<SEO
			title={`${fourOFourText} | ${siteTitle}`}
			description={seoDescription}
		/>
		<Link to="/">{fourOFourText}</Link>
	</StyledFourOFour>
);

export default FourOFour;
